import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import api from 'services/api'
import handleMessageError from 'utils/handleMessageError'
import { useCompanies } from './useCompanies'
import { useToast } from './useToast'
import useToggle from './useToggle'

interface IBidding {
  bid_code: string
  buyer_code: string
  id: number
  message_status: number
  portal: number
  time_last_message: number
}

interface IBiddingFeting {
  data: IBidding[] | []
}

interface IDetailsBidding {
  bid_code: string
  bid_status: number
  buyer_code: string
  company: number
  created_at: number
  dispute_date: null
  edict: number
  id: number
  keywords_bid: null
  message_status: number
  monitoring: boolean
  note: null
  portal: number
  prg_code: string
  time_last_message: number
  updated_at: number
}

interface IDetailsBiddingResponse {
  data: IDetailsBidding
}

interface IBuyer {
  code: string
  id: number
  name: string
  portal: number
  uf: string
}

interface IBuyerResponde {
  data: IBuyer
}

const MonitoringBiddingContext = createContext<any>(null)

const MonitoringBiddingProvider = ({ children }: PropsWithChildren) => {
  const { isOpen: loading, openToggle, closeToggle } = useToggle()
  const [biddingIdCenterNotification, setBiddingIdCenterNotification] = useState<any>(false)
  const [selectedBidding, setSelectedBidding] = useState<IBidding>()
  const [biddingFirebase, setBiddingFirebase] = useState<any>('')
  const [bidding, setBidding] = useState<IBidding[]>([])
  const [infoBidding, setInfoBidding] = useState<IDetailsBidding>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [buyer, setBuyer] = useState<IBuyer>()
  const { company } = useCompanies()
  const { toastError } = useToast()
  const [reloadMonitoring, setRealodMonitoring] = useState(1)

  const activeBidsFirebase = async () => {
    const newBidding = bidding.map(item => {
      return { ...item, active: biddingFirebase?.some((bid: any) => bid === item.id) }
    })

    setBidding(
      // eslint-disable-next-line func-names
      newBidding.sort(function (dataA, dataB) {
        return dataB.active - dataA.active
      })
    )
    await getBuyer()
  }

  useEffect(() => {
    activeBidsFirebase()
    // react-hooks/exhaustive-deps
  }, [biddingFirebase])

  useEffect(() => {
    if (selectedBidding) {
      getBiddingById()
      getBuyer()
    }
  }, [selectedBidding])

  const handleChangeStatus = () => {
    const newBidding = bidding.map(item => {
      return item.id === selectedBidding?.id ? { ...item, message_status: 3, active: false } : item
    })
    setBidding(newBidding)
  }

  const getBiddingById = async () => {
    try {
      const { data } = (await api.get(`monitoring/bid/${selectedBidding?.id}`)) as IDetailsBiddingResponse
      setInfoBidding(data)
    } catch (e: any) {
      toastError('Erro ao buscar licitações de uma licitação especifica.', handleMessageError(e))
    }
  }

  const getBuyer = async () => {
    if (selectedBidding) {
      try {
        const { data } = (await api.get(
          `monitoring/buyer/search?portal=${selectedBidding?.portal}&code=${selectedBidding?.buyer_code}`
        )) as IBuyerResponde
        setBuyer(data)
      } catch (e: any) {
        toastError('Erro ao buscar comprador especifico.', handleMessageError(e))
      }
    }
  }

  const filterBiddingId = (bidding: IBidding[]) => {
    return bidding?.find((item: IBidding) => item.id === biddingIdCenterNotification)
  }

  const getBiddingWithMatched = async () => {
    openToggle()
    try {
      const { data } = (await api.get(
        `monitoring/bids/matched_messages_to_read/company/${company.id}`
      )) as IBiddingFeting
      setBidding(data)
      if (data?.length > 0) {
        if (searchParams.get('bidding_id')) {
          const bidding = data.find((item: IBidding) => item.id === Number(searchParams.get('bidding_id')))
          if (!bidding) {
            handleSelectedBidding(data[0])
          } else {
            handleSelectedBidding(bidding)
          }
        }
      }
    } catch (e: any) {
      toastError('Erro ao buscar licitações com ocorrências.', handleMessageError(e))
    } finally {
      closeToggle()
    }
  }

  const getBidding = async () => {
    openToggle()
    try {
      const { data } = (await api.get(`monitoring/bids/company/${company.id}`)) as IBiddingFeting
      setBidding(data)
      if (data?.length > 0) {
        if (biddingIdCenterNotification) {
          const bidding = filterBiddingId(data)
          handleSelectedBidding(data[0])
        } else {
          handleSelectedBidding(data[0])
        }
      }
    } catch (e: any) {
      toastError('Erro ao buscar licitações.', handleMessageError(e))
    } finally {
      closeToggle()
    }
  }

  const getTodayBidding = async () => {
    openToggle()
    try {
      const { data } = (await api.get(
        `monitoring/messages/all/matched/today?companies=${company.id}&page=0`
      )) as IBiddingFeting
      setBidding(data)
      if (data?.length > 0) {
        if (biddingIdCenterNotification) {
          const bidding = filterBiddingId(data)
          handleSelectedBidding(data[0])
        } else {
          handleSelectedBidding(data[0])
        }
      }
    } catch (e: any) {
      toastError('Erro ao buscar licitações.', handleMessageError(e))
    } finally {
      closeToggle()
    }
  }

  const handleSelectedBidding = (bidding: IBidding) => {
    setSelectedBidding(bidding)
  }

  const reloadContentMonitoring = () => {
    setRealodMonitoring(reloadMonitoring + 1)
  }

  return (
    <MonitoringBiddingContext.Provider
      value={{
        infoBidding,
        buyer,
        selectedBidding,
        bidding,
        loading,
        getBidding,
        handleSelectedBidding,
        setBiddingIdCenterNotification,
        getBiddingWithMatched,
        handleChangeStatus,
        biddingFirebase,
        setBiddingFirebase,
        activeBidsFirebase,
        reloadMonitoring,
        reloadContentMonitoring,
        getTodayBidding,
      }}
    >
      {children}
    </MonitoringBiddingContext.Provider>
  )
}

const useMonitoringBidding = () => {
  return useContext(MonitoringBiddingContext)
}

export { MonitoringBiddingProvider, useMonitoringBidding }
