// eslint-disable-next-line import/no-unresolved
import logoComprasPublicas from 'assets/logo_compras_pulbicas.jpeg'
import logoBrasil from 'assets/logoBrasil.jpeg'
import logoE from 'assets/logo_licitacoez.png'

export const handleImgPortals: any = {
  1: logoE,
  34: logoBrasil,
  31: logoComprasPublicas,
}

const MODALIDADES = [
  { id: 5, value: 'Pregão Eletrônico', text: 'Pregão Eletrônico' },
  { id: 3, value: 'Concorrência Eletrônica', text: 'Concorrência Eletrônica' },
  { id: 6, value: 'Dispensa Eletrônica', text: 'Dispensa Eletrônica' },
]
export const handleTextPortals: Record<number, string> = {
  1: 'Comprasnet',
  34: 'LicitacoesE',
  31: 'Compras Publicas',
}

export const handleStatusBidding = {
  1: logoE,
  6: logoBrasil,
}

export const settingSomMonitoring = {
  1: 'ALERTA_1',
  2: 'ALERTA_2',
  3: 'ALERTA_3',
  4: 'ALERTA_4',
  5: 'ALERTA_4',
  6: 'masculino',
  7: 'feminino',
  8: 'audio',
}
export const USER_CONFIG = 'wc-user-config-app'

const SUCCESS = 'success'
const ERROR = 'error'
const INFO = 'info'
const WARNING = 'warning'
const MENSAGENS = 'Mensagens'
const OCORRENCIAS = 'Ocorrencias'
const TODAS = 'TODAS'
const NOVAS = 'NOVAS'
const COM_OCORRENCIAS = 'COM OCORRÊNCIAS'
const ESPECIFICA = 'Filtro Avançado'
const COMPRADOR = 'Filtro Simples'

const MESSAGE_OCCURRENCE = 1
const MESSAGE_NEW = 2
const MESSAGE_READ = 3

export const audioSrc: any = {
  feminino: 'alerta-feminino',
  masculino: 'alerta-masculino',
  audio: 'audio',
  portal: {
    1: 'comprasnet.mp3',
    6: 'licitações-e.mp3',
  },
  types: {
    1: 'whatsapp.mp3',
    2: 'somAviso.mp3',
    3: 'msg.mp3',
    4: 'sob.mp3',
    5: 'somz.mp3',
    8: 'sua-empresa-foi-citada.mp3',
    9: 'licitacao-com-situacao-atualizada.mp3',
    10: 'encontramos-mensagens-importantes.mp3',
  },
  urgent: 'urgente.mp3',
}

export const routes = {
  LOGIN: '/login',
  REGISTER: '/register',
  SEND_TOKEN: '/send_token_reset',
  RESET_PASSWORD: '/reset_password/:token_reset',
  ACCESS_TOKEN: '/acesses_token/:token',
  ACC_VALIDATION_EMAIL: '/account-validation/:emailId',
  LOGOUT: '/logout',

  DASHBOARD: '/',

  PROSPECTS_LIST: '/prospects/list',
  PROSPECTS_FAVORITES: '/prospects/favorites',
  PROSPECTS_SEARCH: '/prospects/search',
  PROSPECTS_SETTINGS: '/settings/prospects/:id',

  BIDS_LIST: '/bids/list',
  BIDS_DETAILS: '/bids/details/:id',

  PROPOSALS_LIST: '/proposals/list',
  PROPOSALS_HISTORY: '/proposals/history/:sheetId',
  PROPOSALS_HOMOLOGADA: '/proposals/homologada',
  PROPOSALS_DETAILS: '/proposals/details/:sheetId',
  PROPOSALS_CREATED: '/proposals/created',
  PROPOSALS_SETTINGS: '/settings/proposals/:id',

  MONITORING_CHAT: '/monitoring/chat',
  MONITORING_OCCURRENCES: '/monitoring/occurrences',
  MONITORING_SETTINGS: '/settings/monitoring/:id',

  SETTINGS: '/settings/:id',
  CONTRACTS: '/contracts',
  SUPORT_APPS: '/apps',
  NOT_FOUND: '/404',
}

const STATES = [
  { uf: 'AC', state: 'Acre', checked: false, check: true },
  { uf: 'AL', state: 'Alagoas', checked: false, check: true },
  { uf: 'AP', state: 'Amapá', checked: false, check: true },
  { uf: 'AM', state: 'Amazonas', checked: false, check: true },
  { uf: 'BA', state: 'Bahia', checked: false, check: true },
  { uf: 'CE', state: 'Ceará', checked: false, check: true },
  { uf: 'DF', state: 'Distrito Federal', checked: false, check: true },
  { uf: 'ES', state: 'Espírito Santo', checked: false, check: true },
  { uf: 'GO', state: 'Goiás', checked: false, check: true },
  { uf: 'MA', state: 'Maranhão', checked: false, check: true },
  { uf: 'MT', state: 'Mato Grosso', checked: false, check: true },
  { uf: 'MS', state: 'Mato Grosso do Sul', checked: false, check: true },
  { uf: 'MG', state: 'Minas Gerais', checked: false, check: true },
  { uf: 'PA', state: 'Pará ', checked: false, check: true },
  { uf: 'PB', state: 'Paraíba', checked: false, check: true },
  { uf: 'PR', state: 'Paraná', checked: false, check: true },
  { uf: 'PE', state: 'Pernambuco', checked: false, check: true },
  { uf: 'PI', state: 'Piauí', checked: false, check: true },
  { uf: 'RJ', state: 'Rio de Janeiro', checked: false, check: true },
  { uf: 'RN', state: 'Rio Grande do Norte', checked: false, check: true },
  { uf: 'RS', state: 'Rio Grande do Sul', checked: false, check: true },
  { uf: 'RO', state: 'Rondônia', checked: false, check: true },
  { uf: 'RR', state: 'Roraima', checked: false, check: true },
  { uf: 'SC', state: 'Santa Catarina', checked: false, check: true },
  { uf: 'SP', state: 'São Paulo', checked: false, check: true },
  { uf: 'SE', state: 'Sergipe', checked: false, check: true },
  { uf: 'TO', state: 'Tocantins', checked: false, check: true },
]

const CONTRACT_MODULE = [
  {
    id: 1,
    title: 'Prospecção',
    subTitle:
      'Uma solução completa com mais de 3 mil editais captados todos os dias, tornando sua pesquisa e download muito mais ágil.',
  },
  {
    id: 2,
    title: 'Monitoramento',
    subTitle:
      'Não perca tempo apertando F5 no Chat do Pregoeiro e diga adeus a desclassificação por não responder rapidamente.',
  },
  {
    id: 3,
    title: 'Proposta',
    subTitle:
      'Importe sua proposta e cadastre centenas de itens com poucos cliques em apenas alguns minutos sem complicações.',
  },
  {
    id: 4,
    title: 'Disputa',
    subTitle:
      'Envie seus lances com alta performance em diversos itens simultaneamente e aumente suas chances de vitória.',
  },
]

export {
  STATES,
  SUCCESS,
  WARNING,
  INFO,
  ERROR,
  TODAS,
  MESSAGE_NEW,
  NOVAS,
  MESSAGE_OCCURRENCE,
  COM_OCORRENCIAS,
  MESSAGE_READ,
  MENSAGENS,
  OCORRENCIAS,
  ESPECIFICA,
  COMPRADOR,
  CONTRACT_MODULE,
  MODALIDADES,
}
