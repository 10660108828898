import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { defaultsHeadersAxios, api } from 'services/api'
import { UserTypes } from 'types/userTypes'
import { routes, USER_CONFIG } from 'utils/constants'
import { deleteItem, getItem, setItem } from 'utils/persistenceUtils'

export interface UserState {
  user: UserTypes | null
  loginState: 'UNLOGGED' | 'LOGGED' | 'LOADING'
  updateUser: (user: UserTypes | null) => void
  logout: () => void
}
export const UserContext = createContext<UserState | null>(null)

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<UserTypes | null>(null)
  const [loginState, setLoginState] = useState<'UNLOGGED' | 'LOGGED' | 'LOADING'>('LOADING')
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const updateUser = useCallback((newUser: UserTypes | null) => {
    if (newUser) {
      const userStr = JSON.stringify(newUser)
      setItem(USER_CONFIG, userStr)
      defaultsHeadersAxios(newUser.token)
      setUser(newUser)
      setLoginState('LOGGED')
    } else {
      defaultsHeadersAxios('')
      deleteItem(USER_CONFIG)
      setUser(null)
      setLoginState('UNLOGGED')
    }
  }, [])

  useEffect(() => {
    ;(async (): Promise<void> => {
      const urlToken = searchParams.get('token')

      setSearchParams((params: URLSearchParams) => {
        const newParams = new URLSearchParams(params)
        newParams.delete('token')
        return newParams
      })

      if (urlToken) {
        const { data, status } = await api.get('accounts/user/info', {
          headers: { Authorization: `Token ${urlToken}` },
        })
        if (status === 200) {
          return updateUser(data)
        }
      }

      const userStr = getItem(USER_CONFIG)
      if (userStr) {
        const user = JSON.parse(userStr)
        const data_atual = new Date(user.data_atual)
        return updateUser({ ...user, data_atual })
      }
      return updateUser(null)
    })()
  }, [])

  const logout = useCallback(() => {
    api
      .delete('notification/firebase/delete-token')
      .catch(error => {
        if (error?.response?.status !== 401) {
          console.error(new Error('Error on delete token'))
        }
      })
      .then(() => {
        updateUser(null)
        navigate(routes.LOGIN)
      })
  }, [updateUser])

  return <UserContext.Provider value={{ user, updateUser, logout, loginState }}>{children}</UserContext.Provider>
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
