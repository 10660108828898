import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'
import api from 'services/api'
import { CompanyTypes } from 'types/companyTypes'
import { useLoading } from './useLoading'
import { useToast } from './useToast'

import { routes } from '../utils/constants'
import { useUser } from './useUser'

interface CompaniesResponse {
  operating: number
  companies: CompanyTypes[]
}

const CompanyContext = createContext<any>(null)

const CompanyProvider = ({ children }: PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { toastSuccess, toastError } = useToast()
  const { closeLoading, openLoading } = useLoading()
  const [companies, setCompanies] = useState<CompanyTypes[]>([])
  const [company, setCompany] = useState<CompanyTypes | null>(null)
  const [openModalCreateCompany, setOpenModalCreateCompany] = useState<boolean>(false)
  const [openModalUserAnalystOffCompany, setOpenModalUserAnalystOffCompany] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)
  const navigate = useNavigate()
  const { user, loginState } = useUser()
  const { is_client_admin: isClientAdmin } = user || { is_client_admin: false }

  const isInDetailsRoute = useMatch(routes.BIDS_DETAILS)

  const closeModalCreateCompany = () => {
    setOpenModalCreateCompany(false)
  }

  const closeModalUserAnalystOffCompany = () => {
    setOpenModalUserAnalystOffCompany(false)
  }

  const getCompanies = async () => {
    try {
      const { data } = await api.get('management/companies')

      const { companies, operating } = data as CompaniesResponse

      if (companies?.length > 0) {
        const operatingCompany = companies.find((company: CompanyTypes) => company.id === operating)
        let company: CompanyTypes | null = null
        const urlCompanyId = searchParams.get('company_id')
        if (urlCompanyId) {
          const urlCompany = companies.find((companyEl: CompanyTypes) => companyEl.id === Number(urlCompanyId))
          if (urlCompany) {
            company = urlCompany
          }
        }

        if (!company) {
          if (!operating) {
            ;[company] = companies
          } else {
            company = operatingCompany || companies[0]
          }
        }

        if (company) {
          setCompany(company)
          if (company.id !== Number(urlCompanyId)) {
            setSearchParams(params => {
              if (company !== null) {
                params.set('company_id', company.id.toString())
              }
              return params
            })
          }
        }

        setCompanies(companies)
        setUpdate(false)
      } else if (isClientAdmin) {
        setOpenModalCreateCompany(true)
      } else {
        setOpenModalUserAnalystOffCompany(true)
      }
    } catch (e: any) {
      toastError('Erro ao buscar empresas.', '')
    } finally {
      closeLoading()
    }
  }

  const previousCompanyRotaBid = () => {
    if (isInDetailsRoute) navigate(routes.BIDS_LIST)
  }

  const changeCompany = async (dataCompany: CompanyTypes) => {
    previousCompanyRotaBid()
    openLoading()
    try {
      await api.post(`management/company/operating/${dataCompany.id}`)
      const companySelect = companies.find((companyEl: CompanyTypes) => companyEl.id === dataCompany.id)
      setCompany(companySelect || null)
      setSearchParams(params => {
        params.set('company_id', dataCompany.id.toString())
        return params
      })

      toastSuccess('Empresa selecionada.', dataCompany.trading_name)
    } catch (e: any) {
      const messageError = Object?.values(e.response.data).toString()
      toastError('Erro ao cadastrar usuario.', messageError)
    } finally {
      closeLoading()
    }
  }

  const resetState = () => {
    setCompany(null)
    setCompanies([])
  }

  const filterCompanyId = (id: number) => {
    return companies.find(company => company.id === id)
  }

  useEffect(() => {
    if (user) {
      getCompanies()
    }
  }, [user?.username])

  useEffect(() => {
    if (loginState === 'LOGGED') {
      if (company?.id && !searchParams.get('company_id')) {
        setSearchParams(params => {
          params.set('company_id', company.id.toString())
          return params
        })
      }
    } else {
      resetState()
    }
  }, [searchParams, company?.id, user?.username])

  return (
    <CompanyContext.Provider
      value={{
        companies,
        company,
        update,
        setCompanies,
        setOpenModalCreateCompany,
        closeModalCreateCompany,
        openModalUserAnalystOffCompany,
        closeModalUserAnalystOffCompany,
        openModalCreateCompany,
        changeCompany,
        getCompanies,
        filterCompanyId,
        resetState,
        setUpdate,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

const useCompanies = () => {
  const context = useContext(CompanyContext)
  if (!context) {
    throw new Error('useCompanies must be used within a CompanyProvider')
  }
  return context
}

export { CompanyProvider, useCompanies }
